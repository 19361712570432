var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('IconClassBar',{attrs:{"title":"Kommentare","marks":true,"comments":true,"presence":true,"current":"comments"}}),_c('v-card-text',[_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"5"}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.subjects,"filled":"","label":"Fach","dense":"","outlined":"","color":"success"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.students,"item-key":"name","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment")])],1)]}}],null,true)},[_c('span',[_vm._v("Kommentar eingeben")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }