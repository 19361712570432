<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <IconClassBar
          title="Kommentare"
          :marks="true"
          :comments="true"
          :presence="true"
          current="comments"
        />
        <v-card-text>
          <v-row
            align="start"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-select
                :items="subjects"
                filled
                label="Fach"
                dense
                outlined
                color="success"
                class="mt-3"
              ></v-select>
            </v-col>
            <v-spacer />
            <v-col
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="students"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
          >
            <template v-slot:item.name="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="item.img"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.comment="{item}">
              {{ item.comment }}
            </template>
            <template v-slot:item.action="{item}">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-comment</v-icon>
                  </v-btn>
                </template>
                <span>Kommentar eingeben</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue';
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Lernende',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Kommentar', value: 'comment' },
          { text: '', value: 'action' },
        ],
        students: [
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe two',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe two',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend two',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut two',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa two',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe three',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend three',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut three',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa three',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe four',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe four',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend four',
            comment: 'Kommentar 1',
            action: 'add',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut four',
            comment: 'Kommentar 1',
            action: 'add',
          },
        ],
        subjects: [
          'Administration',
          'Wirtschaft und Gesellschaft',
        ]
      }
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
